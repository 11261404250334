<template>
    <div>
        <div :class="$style.subbar" v-if="instance" class="mt-3">
            <div class="topbar--display aC mb-0">
                <ul v-if="false" :class="$style.breadcrumbs" class="mr-4">
                    <li :class="$style.breadcrumb" class="text-dark-purple">
                        {{ instance.name }}
                    </li>
                </ul>
                <a-breadcrumb>
                    <a-breadcrumb-item>{{ instance.name }}</a-breadcrumb-item>
                    <a-breadcrumb-item
                        :style="crumbs.length == 0 ? 'color:var(--orange)' : ''"
                        >{{selectedAppInitial}}</a-breadcrumb-item
                    >
                    <a-breadcrumb-item
                        v-for="(crumb, crumbI) in crumbs"
                        :key="crumbI"
                        :value="crumbI"
                        :style="
                            crumbI == crumbs.length - 1
                                ? 'color:var(--orange)'
                                : ''
                        "
                        >{{ crumb }}</a-breadcrumb-item
                    >
                </a-breadcrumb>

                <div
                    v-if="
                        !$route.path.includes('settings')
                    "
                    :class="$style.amount"
                    class="ml-auto d-none d-sm-flex add-margin"
                >
                    <a-auto-complete
                        style="width: 300px"
                        placeholder="Search for a lot or amenity..."
                        :dropdown-match-select-width="false"
                        :dropdown-style="{ width: '300px' }"
                        option-label-prop="value"
                        @search="handleSearch"
                        class="mr-4"
                        size="large"
                    >
                        <template slot="dataSource">
                            <a-select-option
                                @click="optionClicked(lot.id)"
                                v-for="(lot, lotI) in searchResult"
                                :key="lotI + lot.id"
                                :value="lot.id"
                            >
                                <div>
                                    <div>
                                        <strong>{{ lot.type == "lot" ? `Lot ${lot.name}` : lot.name }}</strong>
                                    </div>
                                    <small class="text-sm">{{ lot.type == "lot" ? "Siteplan Lot" : "Community Amenity" }}</small>
                                </div>
                            </a-select-option>
                        </template>
                        <a-input>
                            <a-icon
                                style="stroke: #000; stroke-width: 34"
                                slot="prefix"
                                type="search"
                            />
                        </a-input>
                    </a-auto-complete>

                    <a-button
                        @click="$store.commit('COLORS_SETTINGS')"
                        type="primary"
						size="large"
                        class="mr-3"
                    >
                        COLORS
                    </a-button>
                    <a-button
                        v-if="!hasChildren"
                        @click="setArchitecturalRules"
                        type="primary"
						size="large"
                    >
                        <span class="btn-addon">
                            <i class="fa fa-edit btn-addon-icon"></i>
                        </span>
                        ARCHITECTURAL RULES
                    </a-button>
					<a-tooltip v-else overlayClassName="change-tooltip-color">
						<template slot="title">
							Architectural rules are not applicable in Group Projects
						</template>
						<a-button
							@click="() => {}"
	                        type="primary"
							size="large"
							:disabled="hasChildren"
	                    >
	                        <span class="btn-addon">
	                            <i class="fa fa-edit btn-addon-icon"></i>
	                        </span>
	                        ARCHITECTURAL RULES
	                    </a-button>
					</a-tooltip>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            searchResult: [],
        };
    },
    computed: {
		selectedAppInitial() {
			return this.$store.state.theApp?.media?.initial || "SPC";
		},
        searchItems() {
            return this.$store.state.siteplan.lots;
        },
        newSearch() {
            let list = [];

            Object.values(this.$store.state.siteplan.lots).forEach((lot) => {
                let obj = JSON.parse(JSON.stringify(lot));
                if (lot.type == "lot") {
                    obj.searchName = `lot ${lot.name}`;
                } else {
                    obj.searchName = lot.name;
                }
                list.push(obj);
            });
            return list;
        },
        crumbs() {
            return this.$store.state.siteplan.crumbs;
        },
        instance() {
            return this.$store.state.instance;
        },
        hasChildren() {
            if (
                Object.keys(this.$store.state.siteplan.children).length != 0 ||
                Object.keys(this.$store.state.siteplan.childLots).length != 0
            )
                return true;
            else return false;
        },
    },
    methods: {
        setArchitecturalRules() {
			if(this.hasChildren){
				return;
			}
            if (this.$p < 40)
                return this.$message.error(
                    "You do not have permission to set architectural rules"
                );
            this.$store.commit("SITEPLAN_RULES");
        },
        optionClicked(id) {
            let selected = this.searchItems[id];
            this.$store.commit("SET_CRUMBS", selected.name);
            this.$store.commit("SELECT_LOT_SHAPE", selected.shapeId);
        },
        handleSearch(input) {
            if (input && input.length > 0) {
                this.searchResult = this.newSearch.filter(({ searchName }) => {
                    searchName = searchName?.toLowerCase();
                    input = input?.toLowerCase();
                    return searchName.includes(input);
                });
            } else {
                return (this.searchResult = []);
            }
        },
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
@media screen and (min-width: 1520px) {
    .topbar--display {
        display: flex;
    }
}
@media screen and (max-width: 1520px) {
    .add-margin {
        margin-top: 10px;
    }
}
</style>
